declare global {
    interface Window {
        handleClickOnCard: (event: MouseEvent, anchorElement: HTMLAnchorElement) => void
        initCardWidgets: () => void
        initTeaserWidgets: () => void
    }
}

window.handleClickOnCard = function (event: MouseEvent, anchorElement: HTMLAnchorElement): void {
    if (window.getSelection()?.toString() === '') { // User is not selecting text
        event.preventDefault();

        if (!anchorElement.href || anchorElement.href === '#' || anchorElement.href === 'javascript:void(0)') {
            return;
        }

        const target = anchorElement.target || '_self';
        if (event.ctrlKey || (target && target !== '_self')) {
            window.open(anchorElement.href, target);
        } else {
            window.location.href = anchorElement.href;
        }

        event.stopPropagation();
    }
};

window.initCardWidgets = function (): void {
    // See widgets/card.scss and local/fwf/Resources/Private/Partials/Common/Card.html
    for (const cardElement of document.querySelectorAll('.card')) {
        const cardLinkElement = cardElement.querySelector<HTMLAnchorElement>('a[data-card-link]');
        if (cardLinkElement) {
            cardElement.addEventListener('click', (event) => {
                window.handleClickOnCard(event as MouseEvent, cardLinkElement); // eslint-disable-line @typescript-eslint/no-unsafe-call
            });
        }
    }
};

window.initTeaserWidgets = function (): void {
    for (const teaserElement of document.querySelectorAll('.frame-type-text')) {
        const teaserLinkElement = teaserElement.querySelector<HTMLAnchorElement>('a[data-teaser-link]');
        if (teaserLinkElement) {
            teaserElement.addEventListener('click', (event) => {
                window.handleClickOnCard(event as MouseEvent, teaserLinkElement); // eslint-disable-line @typescript-eslint/no-unsafe-call
            });
        }
    }
};

export {};
